import React, { useRef } from 'react';
import './Login.css';
import url from '../config/axios'
import brandImage from '../images/brandImage.png'
import { useState } from 'react';
import Popup from "reactjs-popup";
import { useHistory } from "react-router-dom";

function Login() {
    let history = useHistory();
    const ref = useRef();
    const openTooltip = () => ref.current.open();
    const closeTooltip = () => ref.current.close();
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [message, setMessage] = useState({
        display: false,
        status: false,
        text: "",
    });
    const loginUser = () => {
        url.post(`/admin/login/`, { email: email, password: password }).then((res) => {
            console.log(res);
            if (res.data.Message === "Success") {
                // alert("User Logged In Successfully");
                setMessage({
                    text: "Successfully Logged In",
                    status: true,
                    display: true,
                });
                openTooltip();

                localStorage.setItem("AdminDetails", JSON.stringify(res.data.data));
                setTimeout(() => {
                    history.push("/dashboard");
                    //for reloading favicon
                    // window.location.reload();
                }, 1000);
            }
            else {
                setMessage({
                    text: "Invalid Email or Password",
                    status: false,
                    display: true,
                });
                openTooltip();

                // alert(res.data.data);
            }
        }).catch(e => {
            console.log(e)
            setMessage({
                text: "Invalid Email or Password",
                status: false,
                display: true,
            });
            openTooltip();
        });
    }
    return (
        <div className="col-md-12 login">
            <div>
                <div className="logoAuth">
                    <img alt="yo1" src={brandImage}
                        className="img-fluid " width="100" />
                </div>
                <div className="">
                    <div className="group-comb ">
                        <div className="form-group group-comb-2">
                            <label for="exampleFormControlInput1">Email</label>
                            <input type="text" value={email} onChange={(e) => setEmail(e.target.value)} className="form-control" id="exampleFormControlInput1" placeholder="Enter email" />
                        </div>
                    </div>
                </div>
                <div className="">
                    <div className="group-comb ">
                        <div className="form-group group-comb-2">
                            <label for="exampleFormControlInput1">Password</label>
                            <input type="password" value={password} onChange={(e) => setPassword(e.target.value)} className="form-control" id="exampleFormControlInput1" placeholder="Enter password" />
                        </div>
                    </div>
                </div>
                <div className=" rememberMe">
                    <input type="checkbox" id="" name="" value="" /> Remember Me
                </div>
                {/* <div className="forgot-pass">
                    <a href="!#"> Forgot Password ?</a>
                </div> */}
                <div className="">
                    <button className="btn btn-success login-btn" onClick={() => loginUser()}>Login</button>
                </div>
            </div>
            <Popup ref={ref} position="center">
                {message.status ? (
                    <div style={{ textAlign: "center" }}>
                        <i
                            class="fa fa-check"
                            style={{
                                color: "green",
                                fontSize: "70px",
                            }}
                        ></i>
                        <h2 className="popup-heading" style={{ marginTop: "20px" }}>
                            Success
                        </h2>
                        <p>{message.text}</p>
                    </div>
                ) : (
                    <div style={{ textAlign: "center", justifyContent: "center" }}>
                        <i
                            style={{
                                color: "red",
                                fontSize: "70px",
                            }}
                            className="fa fa-ban"
                        ></i>
                        <h2 style={{ marginTop: "20px" }}>Failed</h2>
                        {/* <p className="popup-heading" style={{ marginTop: "20px" }}>
                            {message.text}
                        </p> */}
                        <button
                            style={{ marginLeft: "auto", marginRight: "auto" }}
                            type="button"
                            onClick={closeTooltip}
                            className="btn btn-primary"
                        >
                            Try Again
                        </button>
                    </div>
                )}
            </Popup>
        </div>

    )
}



export default Login;