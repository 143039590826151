/* eslint-disable react-hooks/exhaustive-deps */
import './OutletChainAdmin.css'
import { useState, useEffect, useCallback } from 'react';
import url from '../../config/axios'
import moment from "moment";
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DateTimePicker from "@mui/lab/DateTimePicker";
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import TextField from '@mui/material/TextField';
import { State, City } from 'country-state-city';
import storage from '../../config/firebase';
import data from '../../helpers/countries.json'
import CA from "../../images/CA.svg"
import US from "../../images/US.svg"
import { countries } from '../../helpers/countriesList'
import Cropper from 'react-easy-crop'
import Slider from '@mui/material/Slider';
import getCroppedImg from '../../helpers/cropImage';
import Dialog from '@mui/material/Dialog';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
// import Select from 'react-select';
import { subDays } from "date-fns";
import Box from '@mui/material/Box';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import FormControl from '@mui/material/FormControl'

const OutletChainAdmin = () => {
  const [crop, setCrop] = useState({ x: 0, y: 0 })
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [showCrop, setShowCrop] = useState(false);
  const [open, setOpen] = useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

  const [outlets, setOutlets] = useState([]);
  const [outletName, setOutletName] = useState("");
  const [soleOutlet, setSoleOutlet] = useState(false);
  const [disabledChain, setDisabledChain] = useState(true);
  const [noOfOutlets, setNoOfOutlets] = useState("");
  const [adminName, setAdminName] = useState("");
  const [adminEmail, setAdminEmail] = useState("");
  const [phoneNo, setPhoneNo] = useState("");
  const [phoneCode, setPhoneCode] = useState("");
  const [address, setAddress] = useState("");
  const [city, setCity] = useState("")
  const [state, setState] = useState("")
  const [country, setCountry] = useState("")
  const [renewalDate, setRenewalDate] = useState(null);
  const [editBoolean, setEditBoolean] = useState(false);
  const [showSide, setShowSide] = useState(false);
  const [page, setPage] = useState(1);
  const [totalPage, setTotalPage] = useState("");
  const [minPro, setminPro] = useState("")
  const [image, setImage] = useState("");
  const [imageFile, setImageFile] = useState("");
  const [statesList, setStatesList] = useState([]);
  const [citiesList, setCitiesList] = useState([]);
  const [startDate, setStartDate] = useState(new Date());
  const [startDateEdit, setStartDateEdit] = useState(new Date());
  const [phoneCountry, setPhoneCountry] = useState("")
  const [phoneData, setPhoneData] = useState([{
    "phoneCode": "+1",
    "flag": US,
    "phoneCountry": "US",
    "selected": false
  }, {

    "phoneCode": "+1",
    "flag": CA,
    "phoneCountry": "CA",
    "selected": false
  }
  ])
  // Error messages for outlet chain
  const [outletNameError, setOutletNameError] = useState("");
  const [adminNameError, setAdminNameError] = useState("");
  const [adminEmailError, setAdminEmailError] = useState("");
  const [noofoutleterror, setNoofoutletError] = useState("")
  const [countryError, setCountryError] = useState("")
  const [stateError, setStateError] = useState("")
  const [cityyError, setCityError] = useState("")
  // const [countryCode, setCountryCode] = useState([]);
  const [selectCountry, setSelectCountry] = useState([]);
  const [error, setError] = useState("")
  const [adminID, setAdminID] = useState("");

  useEffect(() => {
    // console.log(Country.getAllCountries())
    GetAllOutlets();
    getCountryCode();
  }, [])

  const [dateRelatedErrors, setDateRelatedErrors] = useState({});
  useEffect(() => {
    // console.log("**********Incoming", { startDate, endDate });
    let data = { ...dateRelatedErrors };
    //manipualte start date
    if (new Date(startDate).toString() === "Invalid Date") {
      data = { ...data, start: "Invalid date" };
    } else if (new Date(startDate) < subDays(new Date(), 1)) {
      data = { ...data, start: "Must be a date today or in the future" };
    } else {
      const { start, ...others } = data;
      data = { ...others };
    }
    // console.log("*************Manipulated date is: ", data);
    setDateRelatedErrors({ ...data });
  }, [startDate]);

  const handleChange = (newValue) => {
    setStartDate(newValue);
  };

  const getCountryCode = () => {
    let x = selectCountry;
    data.map((e) => {
      return x.push({ value: e.dial_code, label: e.flag + e.dial_code, flag: e.flag, code: e.code, dial_code: e.dial_code })
    })
    setSelectCountry(x)
    // console.log(x)
  }

  const setCountryAndGetState = (country) => {
    // console.log(country.split(','), 'country:');
    setCountry(country.split(',')[0]);
    setStatesList(State.getStatesOfCountry(country.split(',')[1]));
    setCountryError("")
    // setStatesList(State.getStatesOfCountry('US'));
  }

  const setStateAndGetCity = (state) => {
    // console.log(state.split(','), 'state:');
    setState(state.split(',')[0]);
    setCitiesList(City.getCitiesOfState(state.split(',')[2], state.split(',')[1]));
    setStateError("")
  }

  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, [])

  const showCroppedImage = useCallback(async (e) => {
    e.preventDefault();
    try {
      const croppedImage = await getCroppedImg(
        image,
        croppedAreaPixels,
        0
      )
      console.log('donee', { croppedImage });
      localStorage.setItem("ImageSet", croppedImage);
      setImage(croppedImage);
      setShowCrop(false);
      setOpen(false);
    } catch (e) {
      console.error(e)
    }
  }, [croppedAreaPixels, image])

  const imageSelector = (event) => {
    if (event.target.files[0]) {
      console.log(event.target.files[0]);
      setImage(URL.createObjectURL(event.target.files[0]));
      setImageFile(event.target.files[0]);
      setOpen(true);
      setShowCrop(true);
    }
  }

  const addOutlet = (image) => {
    try {
      if (outletName === "") {
        setOutletNameError("Outlet name is required")
      }
      if (adminName === "") {
        setAdminNameError("Admin name is required")
      } if (adminEmail === "") {
        setAdminEmailError("Admin email is required")
      } if (noOfOutlets === "") {
        setNoofoutletError("No of outlet are required")
      } if (country === "") {
        setCountryError("Country is required")
      } if (state === "") {
        setStateError("State is required")
      } if (city === "") {
        setCityError("City is required")
      }
      else {
        let body = {
          outletName: outletName,
          soleOutlet: soleOutlet,
          noOfChains: noOfOutlets,
          adminName: adminName,
          adminEmail: adminEmail,
          phone: phoneNo,
          countryCode: phoneCode,
          phoneCountry: phoneCountry,
          address: address,
          image: image,
          city: city,
          state: state,
          country: country,
          renawalDate: renewalDate,
          isActive: disabledChain,
        }
        url.post(`oca`, body).then(async (res) => {
          console.log('res', res);
          if (res.data.Message === "Success") {
            await clearForm();
            await GetAllOutlets();
            await setShowSide(false);
          }
          else {
            setError(res.data.data)
          }
        }).catch(e => console.log(e));
      }
    } catch (e) {
      return e
    }
  }
  const fileUpload = () => {
    // const storageRef = ref(storage, `images/${file.name}`);
    if (imageFile !== "") {
      const uploadTask = storage.ref(`images/${imageFile.name}`).putString(localStorage.getItem("ImageSet"), 'data_url');
      console.log('file upload')
      uploadTask.on(
        "state_changed",
        snapshot => {
          // const progress = Math.round(
          //   (snapshot.bytesTransferred / snapshot.totalBytes) * 100
          // );
        },
        error => {
          console.log(error);
        },
        () => {
          storage
            .ref("images")
            .child(imageFile.name)
            .getDownloadURL()
            .then(async (blob) => {
              console.log(blob);
              await addOutlet(blob);
            });
        });
    }
    else {
      console.log('file empty')
      addOutlet("");
    }
  }

  const fileUpdate = () => {
    // const storageRef = ref(storage, `images/${file.name}`);
    if (imageFile !== "") {
      const uploadTask = storage.ref(`images/${imageFile.name}`).putString(localStorage.getItem("ImageSet"), 'data_url');
      uploadTask.on(
        "state_changed",
        snapshot => {
          // const progress = Math.round(
          //   (snapshot.bytesTransferred / snapshot.totalBytes) * 100
          // );
        },
        error => {
          console.log(error);
        },
        () => {
          storage
            .ref("images")
            .child(imageFile.name)
            .getDownloadURL()
            .then(async (blob) => {
              console.log(blob);
              await updateOutlet(blob);
            });
        });
      setShowSide(false);
    }
    else {
      updateOutlet(image);
    }
  }

  const updateOutlet = (blob) => {
    if (outletName === "") {
      setOutletNameError("Outlet name is required")
    }
    if (adminName === "") {
      setAdminNameError("Admin name is required")
    } if (adminEmail === "") {
      setAdminEmailError("Admin email is required")
    } if (noOfOutlets === "") {
      setNoofoutletError("No of outlet are required")
    } if (country === "") {
      setCountryError("Country is required")
    } if (state === "") {
      setStateError("State is required")
    } if (city === "") {
      setCityError("City is required")
    }
    else {
      let body = {
        outletName: outletName,
        soleOutlet: soleOutlet,
        noOfChains: noOfOutlets,
        adminName: adminName,
        adminEmail: adminEmail,
        phone: phoneNo,
        countryCode: phoneCode,
        phoneCountry: phoneCountry,
        isSubOutlet: soleOutlet ? true : false,
        address: address,
        city: city,
        state: state,
        country: country,
        renawalDate: renewalDate,
        image: blob,
        isActive: disabledChain,
      }
      if (!soleOutlet) {
        body.outletID = ""
      }
      url.put(`oca/?adminID=${adminID}`, body).then(async (res) => {
        console.log('res', res);
        if (res.data.Message === "Success") {


          setShowSide(false);

          setEditBoolean(false);
          clearForm();
          GetAllOutlets();
        }
        else {
          setError(res.data.data)
        }
      }).catch(e => console.log(e));
    }
  }
  const EditOutletChain = (outlet) => {
    // console.log(outlet)
    setShowSide(true);
    setEditBoolean(true);
    setAdminID(outlet.adminID);
    setOutletName(outlet.outletName);
    setSoleOutlet(outlet.soleOutlet);
    setNoOfOutlets(outlet.noOfChains);
    setAdminName(outlet.adminName);
    setAdminEmail(outlet.adminEmail);
    setPhoneNo(outlet.phone);
    setAddress(outlet.address);
    setRenewalDate(outlet.renawalDate);
    setStartDateEdit(outlet.opensAt)
    let newArr = phoneData.map((item, i) => {
      if (outlet?.phoneCountry === item.phoneCountry) {
        console.log("match found at", i)
        return { ...item, selected: true };
      } else {
        return { ...item, selected: false };
      }
    });
    setPhoneData(newArr);
    setPhoneCountry(outlet?.phoneCountry);
    setPhoneCode(outlet?.countryCode)

    setCity(outlet.city);
    setState(outlet.state);
    setCountry(outlet.country);
    setDisabledChain(outlet.isActive);
    setImage(outlet.image);
    setImageFile("");
  }

  const clearForm = async () => {
    setOutletName("");
    setEditBoolean(false);
    setDisabledChain(true);
    setSoleOutlet(false);
    setNoOfOutlets("");
    setAdminName("");
    setAdminEmail("");
    setPhoneNo("");
    setAddress("");
    setCity("");
    setPhoneCountry("");
    setPhoneCode("");
    setState("");
    setCountry("");
    setRenewalDate(null);
    setImage("");
    setImageFile("")
    setCitiesList([]);
    setStatesList([]);
  }

  const GetAllOutlets = async () => {
    url.get(`oca`).then(async (res) => {
      console.log('res all outlets', res.data.data)
      setOutlets(res.data.data);
      setTotalPage(res.data.data.length);
      setminPro(page * 50 - 50 + 1)
    }).catch(e => console.log(e))
  }

  const SearchByOutletName = (value) => {
    console.log('value:', value)
    if (value === "") {
      GetAllOutlets();
    }
    url.get(`/oca/search/?outletName=${value}`).then(async (res) => {
      // console.log(res, 'response:')
      if (res.data.Message === 'Success') {
        setOutlets(res.data.data);
      }
    }).catch(e => console.log(e));
  }

  const decrementPage = () => {
    if (page > 1) {
      setPage(page - 1);
    }
  }

  const incrementPage = () => {
    if (totalPage < 50) {

    }
    else {
      setPage(page + 1);
    }
  }
  const PhoneValidation = (inputText) => {

    var re = /^[0-9\b]+$/
    if (re.test(inputText)) {
      setPhoneNo(inputText)
    }
    else {
      console.log("not setting value")
    }
  }

  const updateFieldChanged = (event) => {

    setPhoneCode(event.target.value.split(",")[0])
    setPhoneCountry(event.target.value.split(",")[1])
    let newArr = phoneData.map((item, i) => {
      if (event.target.value.split(",")[1] === item.phoneCountry) {
        console.log("match found at", i)
        return { ...item, selected: true };
      } else {
        return { ...item, selected: false };
      }
    });
    console.log(newArr, "Latest arr")
    setPhoneData(newArr);
  };
  return (
    <div className="row">
      <div className="col-md-8">
        <div className="card">
          <button className="btn btn-cannaby btn-cat" onClick={() => {
            clearForm();
            setEditBoolean(false);
            setError("")
            setShowSide(!showSide)
          }}>
            <i className="fas fa-plus-circle"></i>
            Add Outlet Chain
          </button>

          <div className="row">
            <div className="col-md-12">
              <div className="row">
                <div className="col-md-6">
                  <div className="input-group">

                    <input type="text" className="form-control table-search" placeholder="Search outlet by name or email" onChange={(e) => SearchByOutletName(e.target.value)} aria-label="Input group example" aria-describedby="btnGroupAddon" />
                    <div className="input-group-prepend">
                      <div className="input-group-text table-search-icon" id="btnGroupAddon">
                        <i className="fas fa-search"></i>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-2 filters-bar">
                  <div className="dropdown">
                    {/* <a className="btn cannaby-light" href="/" role="button" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                      <i className="fas fa-filter"></i>
                    </a> */}

                    <div className="dropdown-menu" aria-labelledby="dropdownMenuLink">
                      <a className="dropdown-item" href="/">Action</a>
                      <a className="dropdown-item" href="/">Another action</a>
                      <a className="dropdown-item" href="/">Something else here</a>
                    </div>
                  </div>
                  <div className="dropdown">
                    {/* <a className="btn cannaby-light" href="/" role="button" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                      <i className="fas fa-filter"></i>
                    </a> */}

                    <div className="dropdown-menu" aria-labelledby="dropdownMenuLink">
                      <a className="dropdown-item" href="/">Action</a>
                      <a className="dropdown-item" href="/">Another action</a>
                      <a className="dropdown-item" href="/">Something else here</a>
                    </div>
                  </div>
                </div>
                <div className="col-md-4 pagination">
                  <span>
                    {minPro}-{outlets.length + minPro - 1} of {totalPage ? totalPage : 0}
                  </span>
                  <div className="btn cannaby-light" onClick={() => decrementPage()} role="button" >
                    <i className="fas fa-chevron-left" ></i>
                  </div>
                  <div className="btn cannaby-light" onClick={() => incrementPage()} role="button">
                    <i className="fas fa-chevron-right"></i>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-12">
              <table className="table table-striped-cust">
                <thead>
                  <tr>
                    <th scope="col">ID</th>
                    <th scope="col">Name</th>
                    <th scope="col"></th>
                    <th scope="col"></th>
                    <th scope="col"></th>

                    <th scope="col"></th>
                    <th scope="col"></th>
                    <th scope="col">Stores</th>
                    <th scope="col">Admin</th>
                    <th scope="col">Registration Date</th>
                    <th scope="col">Status</th>
                    <th scope="col"></th>
                  </tr>
                </thead>
                <tbody>
                  {outlets.map((outlet, i) => {
                    console.log(outlet, "number")
                    return (
                      <tr key={i}>
                        <td>{outlet.consumerId}</td>
                        <td colSpan="6">
                          {outlet.outletName}
                        </td>
                        <td>{outlet.noOfChains === null ? 0 : outlet.noOfChains}</td>
                        <td className="adminName">{outlet.adminName} <br /> <span>{outlet.adminEmail}</span></td>
                        <td>{moment(outlet.createdAt).format("MMM. Do YYYY")}</td>
                        <td className="status" style={outlet.isActive ? {} : { color: "red" }}>{outlet.isActive ? 'Active' : 'Disabled'}</td>
                        <td><i onClick={() => EditOutletChain(outlet)} className="fas fa-pen"></i></td>
                      </tr>
                    )
                  })}
                </tbody>
              </table>
            </div>
          </div>

        </div>
      </div>
      {/* Add Outlet */}
      {showSide && <div className="col-md-4 " >
        <div className="card">
          <div>
            <h4>{editBoolean ? 'Update Outlet Chain' : 'Add Outlet Chain'}</h4>

            <br></br>
            <form className="">
              {error ? <p style={{ fontSize: "12px", color: "red" }}>
                {error}
              </p> : null}
              <div className="group-comb">
                <div className="form-group">
                  <label  >Outlet Chain Name *</label>
                  <input type="text" value={outletName} onChange={(e) => {
                    setOutletName(e.target.value)
                    setOutletNameError("")
                  }} className="form-control" id="exampleFormControlInput1" placeholder="Name" />
                </div>
                <p style={{ color: "red ", paddingLeft: "3%" }}>

                  {outletNameError ? outletNameError : null}
                </p>
              </div>
              <div className="row">
                <div className="col-md-12">
                  <span className="solo-chain">Is this a solo outlet chain?</span>
                </div>
                <div className="col-md-6 switch-col">
                  <label className="switch">
                    <input type="checkbox" checked={soleOutlet} onChange={() => setSoleOutlet(!soleOutlet)} />
                    <span className="slider round"></span>
                  </label>

                </div>
                {!soleOutlet && <div className="col-md-6">
                  <div className="group-comb">
                    <div className="form-group">
                      <label >No of Outlets *</label>
                      <input
                        type="number"
                        max={9}
                        min={0}
                        pattern="[0-9]*"

                        value={noOfOutlets} disabled={soleOutlet}
                        onInput={(e) => {

                        }}
                        onChange={(e) => {



                          if (e.target.value > 9) {
                            setNoofoutletError("Allows digit 0-9")
                          } else {
                            setNoOfOutlets(e.target.value)
                            setNoofoutletError("")


                          }


                        }
                        } className="form-control" placeholder="No of outlets" />
                    </div>
                  </div>
                  <p style={{ color: "red ", fontSize: "12.5px" }}> {noofoutleterror ? noofoutleterror : null}</p>
                </div>}
              </div>

              <div className="group-comb">
                <div className="form-group">
                  <label  >Admin Name *</label>
                  <input type="text" value={adminName} onChange={(e) => {
                    setAdminName(e.target.value)
                    setAdminNameError("")
                  }} className="form-control" id="exampleFormControlInput1" placeholder="Name" />

                </div>
                <p style={{ color: "red ", paddingLeft: "3%" }}> {adminNameError ? adminNameError : null}</p>

              </div>
              <div className="group-comb">
                <div className="form-group">

                  <div className="row">
                    <div className="col-md-5 pin-input" >


                      <Box sx={{ minWidth: 120 }}>
                        <FormControl fullWidth>

                          <Select
                            style={{ backgroundColor: "rgb(233, 236, 239)" }}
                            disabled={!showSide}
                            renderValue={() => phoneData[0].selected === true ? <div style={{ paddingTop: "5px ", paddingLeft: "5px ", height: "13px" }}><img src={phoneData[0].flag} style={{ height: "10px" }} />{phoneData[0].phoneCode + " "}{phoneData[0].phoneCountry}</div> : <div style={{ paddingTop: "5px ", paddingLeft: "5px ", height: "13px" }}><img src={phoneData[1].flag} style={{ height: "10px" }} />{phoneData[1].phoneCode + " "}{phoneData[1].phoneCountry}</div>}
                            defaultValue={phoneData[0].selected === true ? phoneData[0].phoneCode + phoneData[0].phoneCountry : phoneData[1].phoneCode + phoneData[1].phoneCountry}
                            className="myDropDownHeader"
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={phoneData[0].selected === true ? phoneData[0].phoneCode + phoneData[0].phoneCountry : phoneData[1].phoneCode + phoneData[1].phoneCountry}
                            label="Age"
                            onChange={updateFieldChanged}

                          >
                            {phoneData.map((i, index) => {
                              return <MenuItem

                                selected={i.selected}
                                tabIndex={index} value={i.phoneCode + "," + i.phoneCountry}><div style={{ paddingTop: "5px !important", height: "13px" }}><img src={i.flag} style={{ height: "10px" }} />{i.phoneCode + " "}{i.phoneCountry}</div></MenuItem>
                            })}

                          </Select>
                        </FormControl>
                      </Box>
                    </div>
                    <div className="col-md-7">
                      <label>Phone</label>
                      <input

                        maxLength={10}
                        minLength={7}
                        value={phoneNo} onChange={(e) => {
                          PhoneValidation(e.target.value)
                        }} className="form-control" id="exampleFormControlInput1" placeholder="Phone no" />

                    </div>

                  </div>

                </div>
              </div>
              <div className="group-comb">
                <div className="form-group">
                  <label>Email Address *</label>
                  <input type="email" value={adminEmail} onChange={(e) => {
                    setAdminEmail(e.target.value)
                    setAdminEmailError("")
                  }} className="form-control" id="exampleFormControlInput1" placeholder="jeremy@gmail.com" />
                </div>
                <p style={{ color: "red ", paddingLeft: "3%" }}> {adminEmailError ? adminEmailError : null}</p>

              </div>
              <div className="group-comb">
                <div className="form-group">
                  <label>Address</label>
                  <input type="text" value={address} onChange={(e) => setAddress(e.target.value)} className="form-control" id="exampleFormControlInput1" placeholder="36 merly street" />
                </div>
              </div>
              <div className="group-comb">
                <div className="form-group">
                  <label >Country</label>
                  <select
                    style={{ width: "100%" }}
                    onChange={(e) => setCountryAndGetState(e.target.value)}>
                    {country !== "" && <option>{country !== "" ? country : ''}</option>}
                    {country === "" && <option>{country.length === 0 ? 'Select Country First' : country}</option>}

                    <option>Select Country</option>
                    {/* <option>{country !== "" ? country : 'Select Country'}</option> */}
                    {/* <option>United States
                    </option> */}

                    {countries.map((ctr, i) => {
                      // console.log(ctr, 'ctr');
                      return <option key={i} value={`${ctr.name},${ctr.isoCode}`}>{ctr.name}</option>
                    })}
                  </select>
                  {/* <input type="text" value={country} onChange={(e) => setCountry(e.target.value)} className="form-control" id="exampleFormControlInput1" placeholder="Input Country" /> */}
                </div>
                <p style={{ color: "red ", paddingLeft: "3%" }}> {countryError ? countryError : null}</p>
              </div>
              <div className="group-comb">
                <div className="form-group">
                  <label>State</label>
                  <select style={{ width: '100%' }} onChange={(e) => setStateAndGetCity(e.target.value)}>
                    {state !== "" && <option>{state !== "" ? state : ''}</option>}
                    {state === "" && <option>{statesList.length === 0 ? 'Select Country First' : 'Select State'}</option>}
                    {statesList.map((str, i) => {
                      // console.log(str, 'ctr');
                      return (
                        <option key={i} value={`${str.name},${str.isoCode},${str.countryCode}`}>{str.name}</option>
                      )
                    })}
                  </select>
                </div>
                <p style={{ color: "red ", paddingLeft: "3%" }}> {stateError ? stateError : null}</p>
              </div>
              <div className="group-comb">
                <div className="form-group">
                  <label>City</label>
                  <select style={{ width: '100%' }} onChange={(e) => {
                    setCity(e.target.value)
                    setCityError("")
                  }}>
                    {city !== "" && <option>{city !== "" ? city : ''}</option>}
                    {city === "" && <option>{citiesList.length === 0 ? 'Select State First' : 'Select City'}</option>}
                    {citiesList.map((cty, i) => {
                      // console.log(cty, 'ctr');
                      return (
                        <option key={i} value={cty.name}>{cty.name}</option>
                      )
                    })}
                  </select>
                </div>
                <p style={{ color: "red ", paddingLeft: "3%" }}> {cityyError ? cityyError : null}</p>

              </div>
              <div className="group-comb">
                <div className="form-group">

                  <div className="row">
                    <div className="col-md-12">
                      <label>Start Date</label>
                      <LocalizationProvider
                        dateAdapter={AdapterDateFns}
                      >
                        <DateTimePicker
                          style={{ width: "100%" }}
                          value={startDateEdit ? startDateEdit : startDate}
                          onChange={handleChange}
                          minDate={startDateEdit ? Date() : startDateEdit}
                          // maxDate={subDays(new Date(endDate), 1)}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              value={startDateEdit ? startDateEdit : startDate}
                              onChange={(e) =>
                                handleChange(
                                  new Date(e.target.value)
                                )
                              }
                              error={!!dateRelatedErrors.start}
                              helperText={dateRelatedErrors.start}
                            />
                          )}
                        />
                      </LocalizationProvider>
                      {/* <input type="text" className="form-control" id="exampleFormControlInput1" placeholder="Renewal Date" /> */}
                    </div>
                    {/* <div className="col-md-12" >
                      <label>Registration Date</label>
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DatePicker
                          style={{ width: '100%' }}
                          value={renewalDate}
                          minDate={new Date()}
                          onChange={(newValue) => {
                            setRenewalDate(newValue);
                          }}
                          renderInput={(params) => <TextField {...params} />}
                        />
                      </LocalizationProvider>
                    </div> */}

                    {/* <div className="col-md-2 icon-input" >
                      <i className="far fa-calendar"></i>
                    </div> */}
                  </div>

                </div>
              </div>
              <div className="group ">
                <div className="col-md-12 mt-3">
                  <span className="solo-chain">Status</span>

                </div>
                <div className="col-md-8 switch-col">
                  <label className="switch switch-demo">
                    {/* <input type="checkbox" checked={disabledChain} onChange={() => setDisabledChain(!disabledChain)} /> */}
                    <input
                      type="checkbox"
                      checked={disabledChain}
                      onChange={() => setDisabledChain(!disabledChain)}
                    />
                    <span className="slider round"></span>
                    <span
                      className={`${disabledChain ? 'slider-text' : 'disabledColor'}`}
                      style={{ paddingLeft: "81px" }}
                    >
                      {disabledChain ? "Enabled" : "Disabled"}
                    </span>
                  </label>
                </div>

                <div className="group-comb">
                  <div className="form-group img-drop">
                    <label className="custom-file-upload">
                      <input
                        type="file"
                        id="image"
                        name="Image"
                        onChange={imageSelector}
                        accept="image/png, image/jpeg"
                      />
                      Click here upload the Featured Image
                    </label>
                    {image !== "" && <label onClick={() => setImage("")} className="label label-default">X</label>}
                    <img
                      alt=""
                      width="150"
                      height="120"
                      src={image}
                      className="rounded mx-auto d-block galimage"
                    ></img>
                    {showCrop &&
                      <Dialog
                        fullScreen={fullScreen}
                        open={open}
                        fullWidth={true}
                        onClose={() => setOpen(false)}
                        aria-labelledby="responsive-dialog-title"
                      >
                        <div className="App">
                          <div className="crop-container">
                            <Cropper
                              image={image}
                              crop={crop}
                              zoom={zoom}
                              aspect={4 / 3}
                              onCropChange={setCrop}
                              onCropComplete={onCropComplete}
                              onZoomChange={setZoom}
                            />
                          </div>
                          <div className="controls">
                            <Slider
                              value={zoom}
                              min={1}
                              max={3}
                              step={0.1}
                              aria-labelledby="Zoom"
                              onChange={(e, zoom) => setZoom(zoom)}
                              classes={{ root: 'slider' }}
                            />
                          </div>
                          <div>
                            <button className="btn btn-primary crop-btn" onClick={(e) => showCroppedImage(e)}>Crop</button>
                          </div>
                        </div>
                      </Dialog>
                    }
                  </div>
                </div>
              </div>
              <div className="group m-0">
                {editBoolean &&
                  <div className='d-flex'>
                    <button type="button" onClick={fileUpdate} className="btn btn-primary "
                      style={{ width: "30%" }}
                    >Update</button><button
                      style={{ width: "30%" }}
                      className="btn  btn-rnav"
                      onClick={() => {
                        setError("")
                        setShowSide(false);
                      }}
                    >
                      Cancel
                    </button></div>}
                {!editBoolean && <div className='d-flex'><button type="button" onClick={fileUpload} className="btn btn-primary "
                  style={{ width: "30%" }}
                >Make Live</button>
                  <button
                    style={{ width: "30%" }}
                    className="btn  btn-rnav"
                    onClick={() => {
                      setError("")
                      setShowSide(false);
                    }}
                  >
                    Cancel
                  </button></div>}
              </div>
              <p className="createdOn">Created on <span>Jul. 13, 2021 3:20 PM</span></p>
            </form>
          </div>

        </div>

      </div >
      }

    </div >
  )
}


export default OutletChainAdmin;