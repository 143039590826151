import "./Catalog.css";
// import brandImage from '../../images/brandImage.png'
import { useEffect, useState, useRef, Fragment } from "react";
import url from "../../config/axios";
import { useHistory } from "react-router-dom";
import moment from "moment";
import Popup from "reactjs-popup";
import "reactjs-popup/dist/index.css";

const Catalog = () => {
  const history = useHistory();

  const [products, setProducts] = useState([]);
  const [page, setPage] = useState(1);
  const [productId, setProductId] = useState("");
  const [totalPage, setTotalPage] = useState("");
  const [message, setMessage] = useState({
    display: false,
    status: false,
    text: "",
  });
  const [minPro, setminPro] = useState("");
  const ref = useRef();
  // const openTooltip = () => ref.current.open();
  const closeTooltip = () => {
    try {
      ref.current.close();
      if (message.text === "Success") {
        history.push("/catalog");
      }
    } catch (e) {
      //ignore
    }
  };

  useEffect(() => {
    console.log("localstorage", localStorage.getItem("page"));
    let local = localStorage.getItem("page");
    if (local !== null) {
      setPage(parseInt(local));
    }

    getPaginatedProducts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);

  const getAllProducts = () => {
    url
      .get(`/product/`)
      .then(async (res) => {
        // console.log(res);
        setTotalPage(res.data.data.length);
        setminPro(page * 50 - 50 + 1);
        // await setBrands(res.data.data);
      })
      .catch((e) => console.log(e));
  };

  const getPaginatedProducts = () => {
    url
      .get(`/product/all/?page=${page}`)
      .then(async (res) => {
        console.log(res, "paginated");
        setTotalPage(res.data.total);
        setminPro(page * 50 - 50 + 1);
        await setProducts(res.data.data);
      })
      .catch((e) => console.log(e));
  };

  const SearchByProductName = (value) => {
    console.log("value:", value);
    if (value === "") {
      getPaginatedProducts();
    }
    url
      .get(`/product/search/?name=${value}`)
      .then(async (res) => {
        // console.log(res, 'response:')
        if (res.data.Message === "Success") {
          setProducts(res.data.data);
        }
      })
      .catch((e) => {
        console.log(e, "e::");
        setProducts([]);
      });
  };

  const incrementPage = () => {
    let max = products.length + minPro - 1;
    if (totalPage === max) {
    } else {
      setPage(page + 1);
      localStorage.setItem("page", page + 1);
    }
  };

  const decrementPage = () => {
    if (page > 1) {
      setPage(page - 1);
      localStorage.setItem("page", page - 1);
    }
  };

  const moveToEdit = (product) => {
    localStorage.setItem("product", JSON.stringify(product));
    history.push("/editCatalog");
  };

  const deleteCatalog = (productID) => {
    url
      .delete(`/product/?productID=${productID}`)
      .then(async (res) => {
        console.log(res, "res");
        if (res.data.Message === "Failure") {
          setMessage({
            text: "Failed",
            status: true,
            display: true,
          });
          // openTooltip();
        } else {
          setMessage({
            text: "Success",
            status: true,
            display: true,
          });
          closeTooltip();
          getPaginatedProducts();
        }
      })
      .catch((e) => console.log(e));
  };
  const color = ["#03b7a5", "#fc3986", "#ab48d5", "#2bb72b", "#bdd72a"];
  var num = 0;

  function assignColor() {
    var random = Math.floor(Math.random() * color.length);
    while (num === random) {
      random = Math.floor(Math.random() * color.length);
    }
    num = random;
    var value = color[random];
    return value;
  }
  return (
    <div className="row">
      <div className="col-md-12">
        <div className="card card-pro">
          <button
            className="btn btn-cannaby btn-pro"
            onClick={() => {
              history.push("/addCatalog");
            }}
          >
            <i className="fas fa-plus-circle"></i>
            Add Product
          </button>
          <div className="row">
            <div className="col-md-12">
              <div className="row">
                <div className="col-md-6">
                  <div className="input-group">
                    <input
                      type="text"
                      className="form-control table-search"
                      onChange={(e) => SearchByProductName(e.target.value)}
                      placeholder="Search product by name"
                      aria-label="Input group example"
                      aria-describedby="btnGroupAddon"
                    />
                    <div className="input-group-prepend">
                      <div
                        className="input-group-text table-search-icon"
                        id="btnGroupAddon"
                      >
                        <i className="fas fa-search"></i>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-2 filters-bar">
                  <div className="dropdown">
                    {/* <a className="btn cannaby-light" href="/" role="button" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"> */}
                    {/* <i className="fas fa-filter"></i> */}
                    {/* </a> */}

                    <div
                      className="dropdown-menu"
                      aria-labelledby="dropdownMenuLink"
                    >
                      <a className="dropdown-item" href="/">
                        Action
                      </a>
                      <a className="dropdown-item" href="/">
                        Another action
                      </a>
                      <a className="dropdown-item" href="/">
                        Something else here
                      </a>
                    </div>
                  </div>
                  <div className="dropdown">
                    {/* <a className="btn cannaby-light" href="/" role="button" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"> */}
                    {/* <i className="fas fa-filter"></i> */}
                    {/* </a> */}

                    <div
                      className="dropdown-menu"
                      aria-labelledby="dropdownMenuLink"
                    >
                      <a className="dropdown-item" href="/">
                        Action
                      </a>
                      <a className="dropdown-item" href="/">
                        Another action
                      </a>
                      <a className="dropdown-item" href="/">
                        Something else here
                      </a>
                    </div>
                  </div>
                </div>
                <div className="col-md-4 pagination">
                  {!!products.length && minPro >= 1 && (
                    <Fragment>
                      <span>
                        {minPro}-{products.length + minPro - 1} of{" "}
                        {totalPage ? totalPage : 0}
                      </span>
                      <div
                        className="btn cannaby-light"
                        onClick={() => decrementPage()}
                        role="button"
                      >
                        <i className="fas fa-chevron-left"></i>
                      </div>
                      <div
                        className="btn cannaby-light"
                        onClick={() => incrementPage()}
                        role="button"
                      >
                        <i className="fas fa-chevron-right"></i>
                      </div>
                    </Fragment>
                  )}
                </div>
              </div>
            </div>
            <div className="col-md-12">
              <table className="table table-striped-cust">
                <thead>
                  <tr>
                    <th scope="col">Name</th>
                    <th scope="col"></th>
                    <th scope="col"></th>
                    <th scope="col"></th>

                    <th scope="col"></th>
                    <th scope="col"></th>
                    <th scope="col">Category</th>
                    <th scope="col">Brand</th>
                    <th scope="col">Price</th>
                    <th scope="col">Quantity</th>
                    <th scope="col">Last Modified</th>
                    <th scope="col">Status</th>
                  </tr>
                </thead>
                <tbody>
                  {products.map((product, i) => {
                    return (
                      <tr key={i}>
                        <td colSpan="6">
                          <div style={{ display: "flex" }}>
                            {" "}
                            {product.image ? (
                              <img
                                alt="yo1"
                                src={product.image}
                                className="img-fluid "
                                style={{
                                  width: "40px",
                                  height: "40px",
                                  borderRadius: "5px",
                                }}
                              />
                            ) : (
                              <div
                                className="image-thumbnail-div"
                                style={{
                                  backgroundColor: assignColor(),
                                  width: "40px",
                                  height: "40px",
                                  borderRadius: "5px",
                                }}
                              >
                                <span>{product?.name?.substring(0, 1)}</span>
                              </div>
                            )}
                            <span style={{ marginTop: "0.5em" }}>
                              {product.name}
                            </span>
                          </div>
                        </td>
                        <td>
                          {product?.category ? product?.category[0]?.name : ""}
                        </td>
                        <td>{product?.brand ? product?.brand?.name : ""}</td>
                        <td>{product?.price ? product?.price : ""}</td>
                        <td>{product?.quantity ? product?.quantity : ""}</td>
                        <td>
                          {moment(product.updatedAt).format(
                            " MMM. Do YYYY, h:mm A"
                          )}
                        </td>
                        <td className="status">
                          {product.status ? (
                            <span>Active</span>
                          ) : (
                            <span
                              className={`${!product.status ? "colorRed" : ""}`}
                            >
                              Inactive
                            </span>
                          )}
                        </td>
                        <td></td>
                        <td className="flexing">
                          <i
                            onClick={() => moveToEdit(product)}
                            style={{ margin: "10px" }}
                            className="fas fa-pen editIcon"
                          ></i>
                          <i
                            onClick={() => {
                              setProductId(product.productID);
                              // openTooltip();
                            }}
                            className="fas fa-trash deleteIcon"
                            style={{ margin: "10px" }}
                          ></i>
                        </td>
                        <Popup ref={ref} position="center">
                          <div style={{ textAlign: "center" }}>
                            <i
                              class="fa fa-exclamation-triangle"
                              aria-hidden="true"
                            ></i>
                            <h4 className="popup-heading">
                              Do you want to delete this product ?
                            </h4>
                            <button
                              className="btn popup-success-button"
                              onClick={() => {
                                deleteCatalog(productId);
                              }}
                            >
                              Yes
                            </button>
                            <button
                              style={{ marginLeft: "20px" }}
                              type="button"
                              className="btn popup-cancel-button"
                              onClick={closeTooltip}
                            >
                              No
                            </button>
                          </div>
                        </Popup>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Catalog;
