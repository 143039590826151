// import AddBrand from './AddBrand';
import "./Brands.css";
// import brandImage from '../../images/brandImage.png'
import { useEffect, useState, useCallback } from "react";
import url from "../../config/axios";
import storage from "../../config/firebase";
import moment from "moment";
import Cropper from "react-easy-crop";
import Slider from "@mui/material/Slider";
import getCroppedImg from "../../helpers/cropImage";
import Dialog from "@mui/material/Dialog";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import XLSX from "xlsx";

const Brands = () => {
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [showCrop, setShowCrop] = useState(false);
  const [open, setOpen] = useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  const [showComponent, setComponent] = useState(false);
  const [editBoolean, setEditBoolean] = useState(false);
  const [brands, setBrands] = useState([]);
  const [name, setName] = useState("");
  const [slug, setSlug] = useState("");
  const [brandID, setBrandID] = useState("");
  const [file, setFile] = useState("");
  const [image, setImage] = useState("");
  const [page, setPage] = useState(1);
  const [totalPage, setTotalPage] = useState("");

  const [createdDAte, setCreatedDateE] = useState("");

  const [minPro, setminPro] = useState("");
  const [convertedJson, setConvertedJson] = useState([]);

  const ExcelFileUpload = (event) => {
    // console.log(event)
    let selected = event.target.files[0];
    let fileReader = new FileReader();
    fileReader.readAsBinaryString(selected);
    fileReader.onload = (event) => {
      console.log(event);
      let binaryData = event.target.result;
      let workbook = XLSX.read(binaryData, { type: "binary" });
      console.log(workbook);
      workbook.SheetNames.forEach((sheet) => {
        const data = XLSX.utils.sheet_to_json(workbook.Sheets[sheet]);
        console.log(data);
        setConvertedJson(data);
      });
    };
  };

  const uploadMultipleData = async () => {
    const body = {
      json: convertedJson,
    };
    await url
      .post("/brand/multiple", body)
      .then(async (res) => {
        if (res.data.Message === "Success") {
          alert("File Uploaded Successfully");
        } else {
          alert("Error: Brand already exitst");
        }
      })
      .catch((e) => console.log(e));
    setConvertedJson([]);
    await getPaginatedBrands();
    await getAllBrands();
  };

  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);

  const showCroppedImage = useCallback(
    async (e) => {
      e.preventDefault();
      try {
        const croppedImage = await getCroppedImg(image, croppedAreaPixels, 0);
        console.log("donee", { croppedImage });
        localStorage.setItem("ImageSet", croppedImage);
        setImage(croppedImage);
        setShowCrop(false);
        setOpen(false);
      } catch (e) {
        console.error(e);
      }
    },
    [croppedAreaPixels, image]
  );

  const addBrand = async (blob) => {
    await url
      .post(`/brand/`, { name: name, image: blob, slug: slug })
      .then((res) => {
        console.log(res);
        if (res.data.Message === "Success") {
          setName("");
          setBrands([...brands, res.data.data]);
          setImage("");
          setSlug("");
          setFile("");
        } else {
          alert("Error: Brand already exitst");
        }
      })
      .catch((e) => console.log(e));
    await getPaginatedBrands();
    await getAllBrands();
  };

  const updateBrand = async (blob) => {
    await url
      .put(`/brand/?brandID=${brandID}`, {
        name: name,
        image: blob,
        slug: slug,
      })
      .then((res) => {
        // console.log(res);
        setComponent(false);
        setEditBoolean(false);
      })
      .catch((e) => console.log(e));
    await getPaginatedBrands();
  };

  const deleteBrand = async () => {
    await url
      .delete(`/brand/?brandID=${brandID}`)
      .then((res) => {
        // console.log(res);
        if (res.data.Message === "Success") {
          alert("Brand Deleted");
          setComponent(false);
          setEditBoolean(false);
        } else {
          alert("Error:", res.data.data);
        }
      })
      .catch((e) => console.log(e));
    await getPaginatedBrands();
  };

  const editBrand = async (brand) => {
    // await localStorage.setItem('brand', JSON.stringify(brand));
    setName(brand.name);
    setImage(brand.image);
    setSlug(brand.slug);
    setBrandID(brand.brandID);
    // console.log('brand', brand);
    await setEditBoolean(true);
    await setComponent(!showComponent);

    setCreatedDateE(brand.createdAt);
  };

  const setAddBrandComponent = () => {
    setComponent(!showComponent);
    setEditBoolean(false);
  };

  useEffect(() => {
    getPaginatedBrands();
    getAllBrands();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);

  const getPaginatedBrands = async () => {
    // console.log(page, 'page no:')
    await url
      .get(`/brand/all/?page=${page}`)
      .then(async (res) => {
        console.log(res, "paginated");
        await setBrands(res.data.data);
      })
      .catch((e) => console.log(e));
  };

  const getAllBrands = () => {
    url
      .get(`/brand/`)
      .then(async (res) => {
        // console.log(res);
        setTotalPage(res.data.data.length);
        setminPro(page * 50 - 50 + 1);
        // await setBrands(res.data.data);
      })
      .catch((e) => console.log(e));
  };

  const SearchByBrandName = (value) => {
    console.log("value:", value);
    if (value === "") {
      getPaginatedBrands();
    }
    url.get(`/brand/search/?name=${value}`).then(async (res) => {
      // console.log(res, 'response:')
      if (res.data.Message === "Success") {
        setBrands(res.data.data);
      }
    });
  };

  const imageSelector = (event) => {
    if (event.target.files[0]) {
      setImage(URL.createObjectURL(event.target.files[0]));
      setFile(event.target.files[0]);
      setOpen(true);
      setShowCrop(true);
    }
  };

  const incrementPage = () => {
    if (totalPage < 50) {
    } else {
      setPage(page + 1);
    }
  };

  const decrementPage = () => {
    if (page > 1) {
      setPage(page - 1);
    }
  };

  const fileUpdate = () => {
    // const storageRef = ref(storage, `images/${file.name}`);
    if (file !== "") {
      const uploadTask = storage
        .ref(`images/${file.name}`)
        .putString(localStorage.getItem("ImageSet"), "data_url");
      uploadTask.on(
        "state_changed",
        (snapshot) => {
          // const progress = Math.round(
          //   (snapshot.bytesTransferred / snapshot.totalBytes) * 100
          // );
        },
        (error) => {
          console.log(error);
        },
        () => {
          storage
            .ref("images")
            .child(file.name)
            .getDownloadURL()
            .then(async (blob) => {
              console.log(blob);
              await updateBrand(blob);
            });
        }
      );
    } else {
      updateBrand(image);
    }
  };

  const fileUpload = () => {
    // const storageRef = ref(storage, `images/${file.name}`);
    if (file !== "") {
      const uploadTask = storage
        .ref(`images/${file.name}`)
        .putString(localStorage.getItem("ImageSet"), "data_url");

      uploadTask.on(
        "state_changed",
        (snapshot) => {
          // const progress = Math.round(
          //   (snapshot.bytesTransferred / snapshot.totalBytes) * 100
          // );
        },
        (error) => {
          console.log(error);
        },
        () => {
          storage
            .ref("images")
            .child(file.name)
            .getDownloadURL()
            .then(async (blob) => {
              console.log(blob);
              await addBrand(blob);
            });
        }
      );
    } else {
      addBrand("");
    }
  };
  const color = ["#03b7a5", "#fc3986", "#ab48d5", "#2bb72b", "#bdd72a"];
  var num = 0;

  function assignColor() {
    var random = Math.floor(Math.random() * color.length);
    while (num === random) {
      random = Math.floor(Math.random() * color.length);
    }
    num = random;
    var value = color[random];
    console.log(value, "VALUES");
    return value;
  }
  const closeComp = () => {
    setComponent(false);
  };
  return (
    <div className="row">
      <div className="col-md-8">
        <div className="card card-cat">
          <button
            className="btn btn-cannaby btn-cat"
            onClick={() => setAddBrandComponent()}
          >
            <i className="fas fa-plus-circle"></i>
            Add Manufacturer
          </button>
          <div className="row">
            <div className="col-md-12">
              <div className="row">
                <div className="col-md-6">
                  <div className="input-group">
                    <input
                      type="text"
                      className="form-control table-search"
                      onChange={(e) => SearchByBrandName(e.target.value)}
                      placeholder="Search Manufacturer By Name"
                      aria-label="Input group example"
                      aria-describedby="btnGroupAddon"
                    />
                    <div className="input-group-prepend">
                      <div
                        className="input-group-text table-search-icon"
                        id="btnGroupAddon"
                      >
                        <i className="fas fa-search"></i>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-2 filters-bar">
                  <div className="dropdown">
                    {/* <a className="btn cannaby-light" href="/" role="button" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                      <i className="fas fa-filter"></i>
                    </a> */}

                    <div
                      className="dropdown-menu"
                      aria-labelledby="dropdownMenuLink"
                    >
                      <a className="dropdown-item" href="/">
                        Action
                      </a>
                      <a className="dropdown-item" href="/">
                        Another action
                      </a>
                      <a className="dropdown-item" href="/">
                        Something else here
                      </a>
                    </div>
                  </div>
                  <div className="dropdown">
                    {/* <a className="btn cannaby-light" href="/" role="button" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                      <i className="fas fa-filter"></i>
                    </a> */}

                    <div
                      className="dropdown-menu"
                      aria-labelledby="dropdownMenuLink"
                    >
                      <a className="dropdown-item" href="/">
                        Action
                      </a>
                      <a className="dropdown-item" href="/">
                        Another action
                      </a>
                      <a className="dropdown-item" href="/">
                        Something else here
                      </a>
                    </div>
                  </div>
                </div>
                <div className="col-md-4 pagination">
                  <span>
                    {minPro}-{brands.length + minPro - 1} of{" "}
                    {totalPage ? totalPage : 0}
                  </span>
                  <div
                    className="btn cannaby-light"
                    onClick={() => decrementPage()}
                    role="button"
                  >
                    <i className="fas fa-chevron-left"></i>
                  </div>
                  <div
                    onClick={() => incrementPage()}
                    className="btn cannaby-light"
                    role="button"
                  >
                    <i className="fas fa-chevron-right"></i>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-12">
              <table className="table table-striped-cust">
                <thead>
                  <tr>
                    <th scope="col">Name</th>
                    <th scope="col"></th>
                    <th scope="col"></th>
                    <th scope="col"></th>

                    <th scope="col"></th>
                    <th scope="col"></th>
                    <th scope="col">Products</th>
                    <th scope="col">Last Modified</th>
                    <th scope="col" className="hidden">
                      Status
                    </th>
                    <th scope="col"></th>
                  </tr>
                </thead>
                <tbody>
                  {brands.map((brand, i) => {
                    return (
                      <tr key={i}>
                        <td colSpan="6">
                          <div style={{ display: "flex" }}>
                            {" "}
                            {brand.image ? (
                              <img
                                alt="yo1"
                                src={brand.image}
                                className="img-fluid "
                                style={{ width: "40px", height: "40px" }}
                              />
                            ) : (
                              <div
                                className="image-thumbnail-div"
                                style={{
                                  backgroundColor: assignColor(),
                                  width: "40px",
                                  height: "40px",
                                  borderRadius: "5px",
                                }}
                              >
                                <span>{brand?.name?.substring(0, 1)}</span>
                              </div>
                            )}
                            <span style={{ marginTop: "0.5em" }}>
                              {brand.name}
                            </span>
                          </div>
                        </td>
                        <td>{brand.productCount}</td>
                        <td>
                          {moment(brand.updatedAt).format(
                            " MMM. Do YYYY, h:mm A"
                          )}
                        </td>
                        <td className="status hidden">
                          {brand.status ? (
                            <span>Published</span>
                          ) : (
                            <span>Not Published</span>
                          )}
                        </td>
                        <td>
                          <i
                            onClick={() => editBrand(brand)}
                            className="fas fa-pen"
                          ></i>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>

                {/* <tr>
                          <td colspan="6">
                            <img alt="yo5" src={brandImage} 
                              className="img-fluid " width="40" />
                            Mark
                          </td>
                          <td>500</td>
                          <td>Sep 21, 2021 2:30 PM</td>
                          <td className="status">Published</td>
                          <td><i onClick={() => editBrand()} className="fas fa-pen"></i></td>
                        </tr>
                        <tr>
                          <td colspan="6">
                            <img alt="yo2" src={brandImage}
                              className="img-fluid " width="40" />
                            Mark
                          </td>
                          <td>500</td>
                          <td>Sep 21, 2021 2:30 PM</td>
                          <td className="status">Published</td>
                          <td><i className="fas fa-pen"></i></td>
                        </tr>
                        <tr>
                          <td colspan="6">
                            <img alt="yo3" src={brandImage}
                              className="img-fluid " width="40" />
                            Mark
                          </td>
                          <td>500</td>
                          <td>Sep 21, 2021 2:30 PM</td>
                          <td className="status">Published</td>
                          <td><i className="fas fa-pen"></i></td>
                        </tr>
                        
                        <tr>
                          <td colspan="6">
                            <img alt="yo4" src={brandImage}
                              className="img-fluid " width="40" />
                            Mark
                          </td>
                          <td>500</td>
                          <td>Sep 21, 2021 2:30 PM</td>
                          <td className="status">Published</td>
                          <td><i className="fas fa-pen"></i></td>
                        </tr> */}
              </table>
            </div>
          </div>
        </div>
      </div>
      <div className="col-md-4">
        {/* {showComponent && <AddBrand 
                  editBoolean={editBoolean} 
                  setEditBoolean={setEditBoolean} 
                  setComponent={setComponent} 
                  brands={brands}
                  setBrands={setBrands}
                /> } */}
        {showComponent && (
          <div className="card" style={{ position: "fixed", width: "420px" }}>
            <div>
              <div className="headerEA">
                <h4>
                  {editBoolean ? "Edit Manufacturer" : "Add Manufacturer"}
                </h4>
                <button className="btn close-btn" onClick={closeComp}>
                  <i class="fas fa-times"></i>
                </button>
              </div>

              <br></br>
              <form>
                <div className="group-comb">
                  <div className="form-group" style={{ marginBottom: "20px" }}>
                    <label>Manufacturer Name</label>
                    <input
                      type="text"
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                      className="form-control"
                      id="exampleFormControlInput1"
                      placeholder="name"
                    />
                  </div>
                  <div className="form-group">
                    <label>Slug</label>
                    <input
                      type="text"
                      value={slug}
                      onChange={(e) => setSlug(e.target.value)}
                      className="form-control"
                      id="exampleFormControlInput1"
                      placeholder="slug"
                    />
                  </div>
                </div>

                <div className="group-comb">
                  <div className="form-group img-drop">
                    <label className="custom-file-upload">
                      <input
                        type="file"
                        id="image"
                        name="Image"
                        onChange={imageSelector}
                        accept="image/png, image/jpeg"
                      />
                      Click here upload the Featured Image
                    </label>
                    <img
                      alt=""
                      width="150"
                      height="120"
                      src={image}
                      className="rounded mx-auto d-block galimage"
                    ></img>
                    {showCrop && (
                      <Dialog
                        fullScreen={fullScreen}
                        open={open}
                        fullWidth={true}
                        onClose={() => setOpen(false)}
                        aria-labelledby="responsive-dialog-title"
                      >
                        <div className="App">
                          <div className="crop-container">
                            <Cropper
                              image={image}
                              crop={crop}
                              zoom={zoom}
                              aspect={4 / 3}
                              onCropChange={setCrop}
                              onCropComplete={onCropComplete}
                              onZoomChange={setZoom}
                            />
                          </div>
                          <div className="controls">
                            <Slider
                              value={zoom}
                              min={1}
                              max={3}
                              step={0.1}
                              aria-labelledby="Zoom"
                              onChange={(e, zoom) => setZoom(zoom)}
                              classes={{ root: "slider" }}
                            />
                          </div>
                          <div>
                            <button
                              className="btn btn-primary crop-btn"
                              onClick={(e) => showCroppedImage(e)}
                            >
                              Crop
                            </button>
                          </div>
                        </div>
                      </Dialog>
                    )}
                  </div>
                </div>
                <div className="group m-4">
                  {editBoolean && (
                    <button
                      type="button"
                      onClick={fileUpdate}
                      class="btn btn-primary m-2"
                    >
                      Update
                    </button>
                  )}
                  {editBoolean && (
                    <button
                      type="button"
                      onClick={deleteBrand}
                      className="btn btn-danger m-2"
                    >
                      <span>
                        <i className="fa fa-trash-alt"></i>
                      </span>
                    </button>
                  )}
                  {!editBoolean && (
                    <button
                      type="button"
                      onClick={fileUpload}
                      class="btn btn-primary m-2"
                    >
                      Publish
                    </button>
                  )}
                </div>
                {editBoolean ? (
                  <p className="createdOn">
                    Created on{" "}
                    {moment(createdDAte).format(" MMM. Do YYYY, h:mm A")}{" "}
                  </p>
                ) : (
                  ""
                )}

                {!editBoolean && (
                  <div>
                    <form class="md-form">
                      <div className="file-field">
                        <div className="btn btn-primary btn-sm float-left">
                          <label>
                            <input
                              type="file"
                              id="excel"
                              name="excel"
                              onChange={ExcelFileUpload}
                              accept=".xls, .xlsx"
                            />
                            <span className="mr-2">Select Excel File</span>
                            <i className="fas fa-pen"></i>
                          </label>
                        </div>
                        {convertedJson.length !== 0 && (
                          <div
                            className="btn btn-primary"
                            onClick={uploadMultipleData}
                          >
                            Upload Data
                          </div>
                        )}
                      </div>
                    </form>
                  </div>
                )}
              </form>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Brands;
